@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    nav li {
        @apply p-4;
        @apply cursor-pointer;
        @apply transition;
        @apply ease-in;
    }

    nav li.active {
        @apply border-b-2;
        @apply border-red-600;
    }

    nav li:hover {
        @apply md:-translate-y-1;
        @apply md:scale-110;
    }

    video {
        @apply h-screen;
        @apply w-full;
        @apply object-cover;
    }

    body {
        @apply font-light;
        @apply text-gray-700;
        @apply subpixel-antialiased;
    }

}

@layer utilities {

    .main-style {
        @apply w-full;
        @apply h-screen;
    }

    .content-style {
        @apply w-full;
        @apply md:h-screen;
        @apply bg-slate-100;
    }

    .overlay {
        @apply absolute;
        @apply left-0;
        @apply w-full h-full;
        @apply bg-[#0000003e]
    }

    .main-bg-img {
        @apply w-full;
        @apply h-[50vh] md:h-[70vh];
        @apply object-cover;
    }

    .main-content {
        @apply max-w-screen-xl;
        @apply w-full;
        @apply md:h-[30vh];
        @apply bottom-0;
        @apply flex flex-col md:flex-row;
        @apply p-4 md:px-8 py-4 md:py-16;
        @apply mx-auto;
        @apply md:divide-x;
    }

    .main-title-section {
        @apply md:w-2/5 lg:w-1/3 xl:w-1/4;
        @apply flex flex-col;
        @apply justify-center items-center md:items-start;
        @apply text-center md:text-left;
    }

    .main-title-top {
        @apply text-red-600;
        @apply text-xl md:text-2xl;
        @apply md:pb-4 pr-2 sm:pr-0;
    }

    .main-title-bottom {
        @apply text-black;
        @apply text-3xl md:text-4xl;
        @apply font-extralight;
    }

    .main-desc-section {
        @apply md:w-3/5 lg:w-2/3 xl:w-3/4;
        @apply flex flex-col;
        @apply justify-center;
        @apply items-start;
        @apply md:pl-8;
        @apply py-8 md:py-0;
    }

    .card {
        @apply container;
        @apply flex;
        @apply justify-center;
        @apply items-end;
        @apply mx-auto;
        @apply md:hover:scale-110 duration-200;
        @apply hover:border-red-600 hover:border;
    }

    .card-title {   
        @apply uppercase;
        @apply text-base;
        @apply bg-gray-200;
        @apply w-full p-3;
        @apply justify-start flex;
    }

    .portfolio-card-img {
        @apply bg-no-repeat;
        @apply bg-cover;
        @apply bg-center;
        @apply h-[350px];
    }

    .services-card-img {
        @apply bg-no-repeat;
        @apply bg-cover;
        @apply bg-center;
        @apply h-[350px];
    }

    /* Home Section */
    /* hi = home item */
    .hi {
        @apply p-4;
        @apply flex flex-col;
        @apply justify-center items-center;
    }

    .hi-title {
        @apply font-semibold text-sm uppercase;
        @apply pt-4 sm:pt-8 pb-2;
    }

    /* Services sub-sections */
    .sss-style {
        @apply w-full md:h-screen;
        @apply bg-slate-50;
        @apply pt-20;
    }

    .sss-container{
        @apply max-w-screen-xl; 
        @apply w-full h-full;
        @apply mx-auto;
        @apply p-4 md:px-8 pb-8 ;
        @apply flex flex-col;
        @apply justify-center;
    }

    .sss-content {
        @apply grid sm:grid-cols-2;
        @apply gap-8 md:gap-16;
    }

    .sss-text {
        @apply flex flex-col;
        @apply justify-center;
    }

    .sss-title{
        @apply text-center sm:text-start;
        @apply text-2xl;
        @apply font-normal;
        @apply py-4 md:py-8;
    }

    .sss-image{
        @apply flex flex-col;
        @apply justify-center;
    }
    
    /* Portfolio */
    .gallery {
        @apply columns-1 md:columns-3;
        @apply gap-3;
    }

    .gallery .pics {
        @apply cursor-pointer;
        @apply transition duration-300 ease-in;
        @apply mb-3;

    }

    /*
    .model {
        @apply w-screen h-[100vh];
        @apply fixed top-0 left-0;
        @apply flex justify-center items-center;
        @apply bg-black;
        @apply transition-opacity duration-75 ease-in-out;
        @apply hidden;
        @apply overflow-hidden;
        @apply opacity-0;
        @apply z-50;
    } 
*/
    .model.open {
        @apply visible;
        @apply opacity-100;
    } 

    /* Careers Section */
    /* cari = Careers item */
    .cari {
        @apply flex flex-col gap-4;
    }

    .cari-job {
        @apply p-5;
        @apply flex flex-col;
        @apply border border-gray-200;
        @apply bg-white;
        @apply rounded-md;
    }

    /* hs = hour + salary */
    .cari-job-hs-wrapper {
        @apply inline-flex;
        @apply items-center justify-between;
        @apply mb-5;
    }

    .cari-job-hourly {
        @apply rounded-2xl;
        @apply bg-yellow-500;
        @apply w-fit; 
        @apply py-1 px-2;
        @apply text-xs text-white font-bold;
    }

    .cari-job-part-time {
        @apply rounded-2xl;
        @apply bg-lime-500;
        @apply w-fit; 
        @apply py-1 px-2;
        @apply text-xs text-white font-bold;
    }

    .cari-job-full-time {
        @apply rounded-2xl;
        @apply bg-blue-500;
        @apply w-fit; 
        @apply py-1 px-2;
        @apply text-xs text-white font-bold;
    }

    .cari-job-location {
        @apply inline-flex items-center;
        @apply font-normal uppercase text-xs;
        @apply text-gray-500;
    }

    .cari-job-title {
        @apply self-center;
        @apply text-black text-lg font-medium;
    }

    .cari-job-desc {
        @apply my-5;
        @apply text-sm;
    }

    .cari-job-apply {
        @apply self-center;
        @apply text-sm hover:text-red-600 cursor-pointer uppercase font-normal;
    }

    .form-label-title {
        @apply text-sm uppercase font-semibold;
        @apply block;
        @apply mb-3;
    }

    /* Associates Section */
    .assoc_logos {
        @apply md:hover:scale-150 duration-200;
    }

    /* Contact Section */
    /* contact info = ci */
    .ci-item {
        @apply flex flex-col;
        @apply justify-start items-center md:items-start;
    }

    .ci-title {
        @apply font-semibold text-sm;
        @apply uppercase;
        @apply mb-2 lg:mb-4;
    }
    
    .form-field {
        @apply w-full;
        @apply mb-4;
        @apply p-2;
        @apply bg-white;
        @apply border border-gray-300;
    }

    /* Footer Section */
    .fss-container {
        @apply flex flex-col;
    }

    .fss-title {
        @apply text-gray-500;
        @apply mb-2 md:mb-4;
        @apply text-sm font-semibold;
        @apply uppercase;
    }

    /* Buttons */
    .flat-btn-home {
        @apply border-4 border-white;
        @apply font-extrabold text-white;
        @apply p-3;
        @apply hover:bg-red-600 hover:border-red-600 hover:text-gray-200; 
        @apply drop-shadow-xl;
    }

    .flat-btn {
        @apply border-2 border-white;
        @apply font-semibold text-gray-200;             
        @apply p-3;
        @apply bg-red-600 border-red-600;
        @apply drop-shadow-xl;
    }
}
.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000cc;
    transition: opacity .4s ease;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    z-index: 50;
}

/*
.model.open {
    visibility: visible;
    opacity: 1;
} */

.model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model.open svg {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 5px;
    cursor: pointer;
}